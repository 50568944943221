
import { HrEmployees } from './index';
import { HrPsc } from './index';
import { NgTenants } from './index';

export class HrClearanceStatus {
   public clearanceStatusId: number = 0;
   public clearanceStatus: string = null;
   public isDefault: boolean = false;
   public tenantId: number = 0;
   public hrEmployees: HrEmployees[] = [];
   public hrPsc: HrPsc[] = [];
   public tenant: NgTenants = null;
}