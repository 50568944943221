
import { FacEquipmentGroups } from './index';
import { FacEquipmentOwners } from './index';
import { FacEquipmentDocument } from './index';
import { FacEquipmentMeters } from './index';
import { FacFuelIssues } from './index';
import { FacFuelLevels } from './index';
import { FacGeneratorsDeadlined } from './index';
import { FacOilAdditions } from './index';
import { FacPressureReadings } from './index';
import { FacRefrigerantTransfers } from './index';
import { FacRefrigerationEquipment } from './index';
import { FacTankInchesToGallons } from './index';
import { FacWeeklyReadings } from './index';
import { InvScheduleItemXAsset } from './index';
import { HrEmployees } from './index';
import { AllLocations } from './index';
import { FacManufacturers } from './index';
import { AllSites } from './index';
import { FacStandardReportingDesignator } from './index';
import { FacEquipmentSubEquipmentGroups } from './index';
import { AllSubLocations } from './index';
import { NgTenants } from './index';
import { WoScheduledMaintenanceTemplateEquipmentXs } from './index';
import { WoWorkOrderDetailLabor } from './index';
import { WoWorkOrders } from './index';
import { HrWorkSections } from './index';

export class FacEquipment {
   public equipmentId: number = 0;
   public equipmentIdOld: string = null;
   public equipmentOwnerId: number = 0;
   public manufacturerId: number = null;
   public equipmentGroupId: number = null;
   public subEquipmentGroupId: number = null;
   public description: string = null;
   public siteId: number = 0;
   public locationId: number = null;
   public subLocationId: number = null;
   public serialNumber: string = null;
   public model: string = null;
   public datePurchased: Date = null;
   public idTagNumber: string = null;
   public filePlanNumber: string = null;
   public additionalInfo: string = null;
   public active: boolean = false;
   public proposedEquipId: string = null;
   public b017: boolean = false;
   public equipmentIdOriginal: string = null;
   public newFilePlanNumber: string = null;
   public lastEditedBy: string = null;
   public lastEditedOn: Date = null;
   public fuelType: string = null;
   public safeFillCapacity: number = null;
   public shellCapacity: number = null;
   public weightDry: number = null;
   public modelYear: Date = null;
   public tankWidth: number = null;
   public tankDepth: number = null;
   public tankDiameter: number = null;
   public tankLength: number = null;
   public tankPwsCapacity: number = null;
   public tankUnobtainableInches: number = null;
   public tankUnobtainableGallons: number = null;
   public safeFillInches: string = null;
   public fuelIssueable: boolean = null;
   public isSystem: boolean = false;
   public allowSiteScheduledMaintenanceGeneration: boolean = false;
   public parentEquipmentId: number = null;
   public sharePointLink: string = null;
   public cost: number = null;
   public tenantId: number = 0;
   public standardReportingDesignatorId: number = null;
   public workSectionId: number = null;
   public isMissionCritical: boolean = false;
   public lastCalibratedDate: Date = null;
   public nextCalibrationDue: Date = null;
   public nsn: string = null;
   public customerAssetTag: string = null;
   public assignedTo: string = null;
   public assignedDate: Date = null;
   public inventoryRequired: boolean = null;
   public lastInventoriedDate: Date = null;
   public nextInventoryDate: Date = null;
   public lastEditedDate: Date = null;
   public lastEditedById: number = null;
   public equipmentGroup: FacEquipmentGroups = null;
   public equipmentOwner: FacEquipmentOwners = null;
   public facEquipmentDocument: FacEquipmentDocument[] = [];
   public facEquipmentMeters: FacEquipmentMeters[] = [];
   public facFuelIssues: FacFuelIssues[] = [];
   public facFuelLevels: FacFuelLevels[] = [];
   public facGeneratorsDeadlined: FacGeneratorsDeadlined[] = [];
   public facOilAdditions: FacOilAdditions[] = [];
   public facPressureReadings: FacPressureReadings[] = [];
   public facRefrigerantTransfers: FacRefrigerantTransfers[] = [];
   public facRefrigerationEquipment: FacRefrigerationEquipment[] = [];
   public facTankInchesToGallons: FacTankInchesToGallons[] = [];
   public facWeeklyReadings: FacWeeklyReadings[] = [];
   public invScheduleItemXAsset: InvScheduleItemXAsset[] = [];
   public inverseParentEquipment: FacEquipment[] = [];
   public lastEditedByNavigation: HrEmployees = null;
   public location: AllLocations = null;
   public manufacturer: FacManufacturers = null;
   public parentEquipment: FacEquipment = null;
   public site: AllSites = null;
   public standardReportingDesignator: FacStandardReportingDesignator = null;
   public subEquipmentGroup: FacEquipmentSubEquipmentGroups = null;
   public subLocation: AllSubLocations = null;
   public tenant: NgTenants = null;
   public woScheduledMaintenanceTemplateEquipmentXs: WoScheduledMaintenanceTemplateEquipmentXs[] = [];
   public woWorkOrderDetailLabor: WoWorkOrderDetailLabor[] = [];
   public woWorkOrders: WoWorkOrders[] = [];
   public workSection: HrWorkSections = null;
}